.container {
  margin-bottom: 30px;
}

.contentContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px 0 10px 0;
}

.header {
  margin: 10px 0 10px 0;
}
