.container {
  width: 40%;
}

.containerCollapsed {
  width: 100%;
}

.content {
  margin-bottom: 30px;
}

.infoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 10px 0 10px 0;
}

.name {
  font-family: var(--ffm-secondary);
  font-size: 1.2rem;
}

.link {
  color: var(--c-text-secondary);
  font-family: var(--ffm-secondary);
}

.picture {
  width: 70%;
}

.imageContainer {
  padding: 10px 0 10px 0;
}
