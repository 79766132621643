.text {
  text-align: center;
  padding-top: 1.375rem;
  padding-bottom: 2.375rem;
  font-family: var(--ffm-secondary);
  font-size: 1.2rem;
}

.line {
  color: var(--c-text);
}
