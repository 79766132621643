@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");
@import url("https://fonts.googleapis.com/css2?family=VT323&family=ZCOOL+QingKe+HuangYou&display=swap");

:root {
  --c-text: #0465c6;
  --c-text-secondary: #865ab8;
  --c-bg: #fdf8e2;
  --ffm: "VT323", monospace;
  --ffm-secondary: "ZCOOL QingKe HuangYou", sans-serif;
}

.container {
  display: flex;
  flex-direction: column;
  flex-direction: flex-start;
  align-items: center;
  padding: 5vh 20vw 5vh 20vw;
}

.sectionsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.sectionsContainerCollapsed {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formContainer {
  width: 80%;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 50px;
}

html {
  background-color: var(--c-bg);
}

h1 {
  color: var(--c-text);
  margin: 0;
  font-family: var(--ffm);
  font-size: 2rem;
}

h2 {
  color: var(--c-text);
  margin: 0;
  font-family: var(--ffm);
  font-weight: lighter;
}

h2 a {
  color: var(--c-text);
  text-decoration: none;
  display: block;
}

h3 {
  color: var(--c-text-secondary);
  font-family: var(--ffm-secondary);
  margin: 0;
}

p {
  color: var(--c-text-secondary);
  font-family: var(--ffm-secondary);
  margin: 0;
}

p a {
  color: var(--c-text);
  text-decoration: none;
  display: block;
}

input {
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 0.3rem;
  border: solid 0.02rem var(--c-text-secondary);
}

textarea {
  width: 100%;
  gap: 0.4rem;
  margin-top: 0.3rem;
  border: solid 0.02rem var(--c-text-secondary);
  height: 10vh;
}
